import React, { useEffect, useState } from "react";
import { BaseModal, InputField, SmallButtonYellow } from 'component-library'
import TwoFactorAuthInputs from "@components/2fa/TwoFactor";
import { login, twoFactorCode } from "@api/API";

import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";
import { useUser } from "@context/UserContext";
import { EmailCheck } from "@regex/EmailCheck";
import { useNotification } from "@context/Notification/NotificationContext";
import { Turnstile } from "@marsidev/react-turnstile";


type EmailPassModalProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  origin?: string;
  darkMode?: boolean;
  product: string;
}

export const EmailPassModalConstructor = ({ openModal, toggleModal, showCloseButton = false, origin = '', product = '', darkMode = true }: EmailPassModalProps) => {
  const { t } = useTranslation()
  const { notify } = useNotification();
  const TURNSTILE_KEY = import.meta.env.VITE_TURNSTILE_SITE_KEY
  const ref = React.useRef()
  // const [failedLoginAttempts, setFailedLoginAttempts] = useState(0);
  const [showTurnstile, setShowTurnstile] = useState(false)
  const BASE_URL = import.meta.env.VITE_BASE_URL as string
  const customNavigate = useCustomNavigate();
  const [wrongPass, setWrongPass] = useState(false)
  const [wrongCode, setWrongCode] = useState(false)
  const [isPageLoading, setIsLoading] = useState(false)
  const [showContent, setShowContent] = useState(false)
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: ''
  }) 
  const initialInputValues = Array(6).fill('');
  const [inputValues, setInputValues] = useState<string[]>(initialInputValues);
  const { user, fetchUserDetails, isAuth, isLoading } = useUser(); 
  const handleInputChange = (newInputValues: string[]) => {
    setInputValues(newInputValues);
  };
  // const [userRole, setUserRole] = useState('' as string)
  const updateLoginDetails = (value: string, state: string) => {
    // console.log('updatelogindetails', value, state)
    setLoginDetails({
      ...loginDetails, 
      [state]: value
    })
  }

  const [turnstileToken, setCloudflareTurnstileToken] = useState("")

  const handleLogin = async () => { 
    if (ref.current) (ref.current as HTMLFormElement).reset();
    setCloudflareTurnstileToken("")
    setShowTurnstile(false)
    setIsLoading(true);
    if(showContent){
      const response = await twoFactorCode(inputValues.join(''))
      // if(response.status !== 200) setFailedLoginAttempts(failedLoginAttempts+1)
      if(response.status !== 200) setShowTurnstile(true)
      if(response.status === 200){
        setUserDetails() 
      }
      else if (response.data.message === 'Locked out') {
        notify(t('lockedOutError'), 'error')
        setWrongCode(true)
        setIsLoading(false)
      } else {
        setWrongCode(true)
        setIsLoading(false)
      }
    }
    else {
      const response = await login(loginDetails.email, loginDetails.password, turnstileToken)
      // if(response.status !== 200) setFailedLoginAttempts(failedLoginAttempts+1)
      if(response.status !== 200) setShowTurnstile(true)

      if(response.status === 200){
        setUserDetails() 
      } else if (response.status === 202){ //2fa required
        setShowContent(true)
        setIsLoading(false)
      } else if (response.data.message === 'Locked out') {
        notify(t('lockedOutError'), 'error')
        setWrongPass(true)
        setIsLoading(false)
      } else {
        notify(t('emailPassError'), 'error')
        setWrongPass(true)
        setIsLoading(false)
      } 
    }
  }
 
  const setUserDetails = async () => {
    try {  
      if(fetchUserDetails){
        fetchUserDetails()
      } 
    } catch (error) {
      console.log('Failed to fetch user details', error); 
    }
  };

  useEffect(() =>{
    if(user)
      if(!isLoading){
        if(isAuth){
          handleNavigation(user.role)
        }
      }
  }, [isAuth, isLoading, user])

  // useEffect(() => {
  //   if(user?.role){
  //     handleNavigation(user.role)
  //   }
  // }, [user])

  const handleNavigation = (role: string) => {
    if(product?.length > 0){
      window.location.href = `https://www.cjp.nl/${product}`
    }
    else if (origin === 'service'){
      window.location.href = (getUrl('admin') as string) + '/r/'
    }
    else if(platformRoles.admin.includes(role)){
      window.location.href = getUrl('admin') as string
    } 
    else if(platformRoles.mijn.includes(role)){
      customNavigate('/account')
    } 
    else if(origin?.length > 0){
      if(canAccessOrigin(role, origin)){
        window.location.href = getUrl(origin) as string;
      }
      else {
        customNavigate('/origin')
      }
    }
    else if(platformRoles.scholen.includes(role)){
      window.location.href = getUrl('scholen') as string
    }
    else {
      customNavigate('/origin')
    }  
  }

  const checkDisabled = () => {
    if(showTurnstile && turnstileToken === "") return true
    if(showContent){
      return inputValues.includes('')
    }
    else {
      return loginDetails.email.length == 0 || loginDetails.password.length == 0
    }
  }

  const getUrl = (selected: string) => {
    const url = `http://${selected}.${BASE_URL}`
    return url
  } 

  const platformRoles = {
    admin: ['admin', 'cjp_admin', 'cjp_director', 'cjp_members_head', 'cjp_finance_head', 'cjp_marketing_head', 'cjp_user', 'cjp_viewer' ],
    scholen: ['school_for_budgetholder'],
    mijn: ['individual', 'school_for_student', 'parent', 'school_for_teacher', 'school_for_student'],
    partners: ['part_comp_for_key_person']
  } as any;

  const canAccessOrigin = (role: any, origin: any): boolean => {
    const allowedRoles = platformRoles[origin];
    return allowedRoles.includes(role);
  } 

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !checkDisabled()) {
        handleLogin();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [loginDetails]);

  return(
    <BaseModal
      open={openModal}
      toggleOpen={toggleModal}
      darkMode={darkMode}
      modalTitle={t('login')}
      modalSubtitle=""
    >  
      <div className="w-[84vw] md:w-full flex flex-col flex-1"> 
        {!showContent ?
          <div className='flex flex-col gap-y-4 mt-2 mb-8 flex-1'>
            <InputField
              placeholder={t("email")}
              value={loginDetails.email}
              setState={() => {}}
              type="text"
              error={loginDetails.email.length > 0 && !EmailCheck(loginDetails.email)}
              errorMsg={t("emailError")}
              handleUpdate={updateLoginDetails}
              itemKey={'email'}
              darkMode={darkMode}
              name="email"
            /> 
            <InputField
              placeholder={t("password")}
              value={loginDetails.password}
              // setState={() => {}}
              type="password"
              error={wrongPass}
              handleUpdate={updateLoginDetails}
              itemKey={'password'}
              darkMode={darkMode}
              errorMsg={t("passwordError")}
              name="password"
            />
            <div className="text-left cursor-pointer underline pt-2" onClick={()=>customNavigate('/wachtwoord')}>
              {t("forgotPassword")}
            </div>
          </div>
          :
          <div className="w-full">
            <TwoFactorAuthInputs value={inputValues} onChange={handleInputChange} error={wrongCode} />
          </div>
        }
        {showTurnstile && 
          <div className="w-fit m-auto">
            <Turnstile
              siteKey={TURNSTILE_KEY}
              // siteKey={'0x4AAAAAAAdWDS1_QWLH1TY9'}
              ref={ref}
              options={{
                action: 'submit-form',
                theme: 'light',
                language: 'nl',
                // size: 'compact',
              }}
              // scriptOptions={{
              //   appendTo: 'body'
              // }}
              onSuccess={(token: string) => setCloudflareTurnstileToken(token)}
            />
          </div>
        }
        <div className='w-full px-8 mt-8 '>
          <SmallButtonYellow
            name="login"
            text={t("login")}
            onClick={() => handleLogin()}
            disabled={checkDisabled()}
            darkMode={darkMode}
            loading={isPageLoading}
          />
        </div>
        {origin === 'scholen' &&
          <div className="text-center cursor-pointer underline mt-8" onClick={()=>window.location.href = 'https://scholen.cjp.nl/aanmelden-school'}>
            {"Nieuwe school aanmelden"}
          </div>
        }
        {origin === 'mijn' &&
          <div className="text-center cursor-pointer underline mt-8" onClick={()=> customNavigate('/account-aanmaken')}>
            {"Nieuw account aanmaken"}
          </div>
        }
        {!origin &&
          <div className="text-center cursor-pointer underline mt-8" onClick={()=> customNavigate('/account-aanmaken')}>
            {"Nieuw account aanmaken"}
          </div>
        }
        {origin === 'partners' &&
          <div className="text-center cursor-pointer underline mt-8" onClick={()=>window.location.href = 'https://partners.cjp.nl/partner/registration'}>
            {"Nieuwe organisatie aanmelden"}
          </div>
        }
      </div>
    </BaseModal>
  )
}